.document-table-rich-text ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
  }
  
.document-table-rich-text ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.document-table-rich-text a {
    @apply hover:text-primary-600 underline font-semibold;
}