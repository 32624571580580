.atm-slider-rich-text ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
}
  
.atm-slider-rich-text ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.atm-slider-rich-text a {
    @apply hover:opacity-50 underline text-primary-600;
}

.atm-slider-rich-text h1 {
    @apply font-bold text-h1;
}

.atm-slider-rich-text h2 {
    @apply font-bold text-h2;
}

.atm-slider-rich-text h3 {
    @apply font-bold text-h3;
}

.atm-slider-rich-text h4 {
    @apply font-bold text-h4;
}

.atm-slider-rich-text h5 {
    @apply font-bold text-h5;
}

.atm-slider-rich-text h6 {
    @apply font-semibold text-body-5;
}