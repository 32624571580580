.document-rich-text ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.document-rich-text ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  @apply mt-4;
}

.document-rich-text p {
  @apply mt-4;
}

.document-rich-text p:last-child {
  @apply mb-0;
}

.document-rich-text a {
  @apply hover:opacity-50 underline font-semibold text-primary-600;
}

.document-rich-text h1 {
  @apply text-gray-600 font-bold mb-4 text-h1;
}

.document-rich-text h2 {
  @apply text-gray-600 font-bold mb-4 text-h2;
}

.document-rich-text h3 {
  @apply text-gray-600 font-bold mb-4 text-h3;
}

.document-rich-text h4 {
  @apply text-gray-600 font-bold mb-4 text-h4;
}

.document-rich-text h5 {
  @apply text-gray-600 font-bold mb-4 text-h5;
}

.document-rich-text h6 {
  @apply text-gray-600 font-semibold mb-4 text-h6;
}

